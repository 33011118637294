.Layout {
  /* display: flex; */
}
/* * {
  padding: 0px;
  margin: 0px;
} */
.snap {
  font-size: 15px;
  margin-right: 10px;
  cursor: pointer;
  /* flex: 3; */
}
.RevDiv {
  position: fixed;
  bottom: 15px;
  background-color: #e726a0;
  padding: 2px 16px;
  border-radius: 50px;
  font-size: 15px;
  right: 20px;
  box-shadow: 1px 0px 11px #b3458bb3;
  cursor: pointer;
  z-index: 6000;
}

.RevToday {
  color: white;
}

.RevDiv .Collapsible {
  display: none;
}
.snap:hover {
  color: blue;
}
.Header ul {
  text-decoration: none;
  display: flex;
  list-style: none;
  font-size: 13px;
  margin-right: 20px;
}
.OfflineStyle {
  background-color: #222;
  color: white;
  padding: 10px;
  /* max-width: 1400px; */
  margin: auto;
}
/* .navs{
  display: flex;
} */
.UserName {
  background: #278ecf;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  border-radius: 50%;
  color: white;
}

.MessagingAppsCont {
  /* background: gold; */
  position: relative;
  display: flex;
  align-items: center;
}
.MessagingAppsCont:hover .MessagingApps {
  display: block;
}
.Header ul > li {
  padding: 8px;
  color: blue;
}
.Header ul li:first-child {
  cursor: pointer;
  color: blue;
  margin-right: 3px;
}
.Header ul > li a {
  /* padding: 8px; */
  font-size: 12px;
  color: blue;
  text-decoration: none;
  cursor: pointer;
}
.Header ul > li a:hover {
  /* padding: 8px; */

  /* color: red; */
}

.MessagingApps {
  position: absolute;
  display: none;
  top: 20px;
  /* left: -20px; */
  /* right: 740px; */
  width: 200px;
  box-shadow: 1px 0px 6px #eee;
  background: #fff;
  z-index: 1000;
}
.MessagingApps div {
  /* padding: 6px; */
  /* margin: 20px 0px; */
}
.MessagingApps div.fullWidth {
  /* background: rgb(179, 7, 7); */
  padding: 10px;
  /* color: white; */
}
.MessagingApps div.fullWidth:hover {
  background: rgb(179, 7, 7);
  /* padding: 4px; */
  color: white;
}
.MessagingApps a {
  color: #444;
  transition: 0.5s;
  text-decoration: none;
  width: 100%;
  /* padding: 6px; */
}
.MessagingApps a:hover {
  /* background: rgb(179, 7, 7); */
  color: white;
  /* text-decoration: none; */
}

.Header {
  /* height: 30px; */
  /* padding: 1px; */
  /* margin-left: 8px; */
  box-shadow: 1px 2px 14px #eee;
  /* font-size: 40px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
}
.settings {
  font-family: arial;
  margin-right: 30px;
}
.settings button {
  padding: 10px;
  outline: none;
  border: none;
  background: none;
  border: 1px solid #444;
  transition: 0.4s;
}
.settings button:hover {
  color: white;
  background: #444;
  transition: 0.4s;
  cursor: pointer;
}
.liveStatus {
  padding: 0;
  margin: 0;
  /* flex: 1; */
  /* font */
  font-family: arial;
  font-size: 15px;
}
.logo {
  padding: 15px;
  margin: 0px;
}
.logo img {
  /* flex: 10; */
  text-align: center;
  /* margin-left: 25px; */
}

.ChildrenPanel {
  /* width: 80%; */
}

.LeftPanel {
  /* width: 20%; */
  flex: 1;
}

.PanelCont {
  /* display: flex; */
}
.DesktopOnly {
  display: none !important;
}

.BalanceButton {
}
.Header ul {
  display: none;
}
.SnackBar {
  display: block;
  padding: 10px;
  width: 46px;
}

.SnackBar div {
  height: 5px;
  background: black;
  margin-bottom: 6px;
}
.BalanceButton a {
  background: #f93961;
  color: white !important;
  border-radius: 10px;
  padding: 10px;
}
.SideBar {
  position: fixed;
  height: 100vh;
  width: 60%;
  transform: translateX(-120%);
  z-index: 4000;
  background: white;
  box-shadow: 1px 0px 14px #333;
  transition: 0.4s;
}

.slideRight {
  transition: 0.4s;
  transform: translateX(0%);
}
.Backdrop {
  position: fixed;
  display: none;
  z-index: 3000;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.Appear {
  display: block;
}
.Content {
  padding: 12px;
  padding-left: 2px;
  text-align: left;
  height: 100vh;
  overflow-y: auto;
}
.Content li {
  list-style: none;
  padding: 10px;
  border-bottom: 1px solid #eee;
}
.Content li a {
  text-decoration: none;
  color: #444;
}

@media (min-width: 900px) {
  .Header ul {
    display: flex;
  }
  .SnackBar {
    display: none;
  }
  .DesktopOnly {
    display: flex !important;
  }
  .SideBar {
    display: none;
  }
  .Backdrop {
    display: none;
  }
}

@media (max-width: 400px) {
  .logo img {
    width: 160px;
  }
}
