.EnterpriseNumberChange {
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
}

.selecttext {
  font-weight: 200;
}
.Select1 select {
  width: 100%;
  padding: 10px 10px;
  margin: 0px 0px 10px;
}

.SimList {
  list-style: none;
  padding: 0px;
  /* max-width: 1000px; */
}
.Title {
  display: flex;
  max-width: 1000px;
  align-items: center;
  justify-content: space-between;
  flex-flow: column;
  /* padding: 10px; */
  /* background: green; */
}
.SimList li {
  /* background: rgba(245, 244, 244, 0.836); */
  padding: 10px;
  margin: 20px 0px;
  border-radius: 10px;
  box-shadow: 1px 0px 7px #eee;
  background: white;
}
.Soft {
  font-weight: 200;
}
.status {
  /* max-width: 100px; */
  display: flex;
  justify-content: start;
  align-items: center;
  color: white;
  padding: 5px;
  font-size: 15px;
  display: inline;

  border-radius: 5px;

  font-weight: 800;
}
.actions {
  display: flex;
  /* flex-flow: column; */
  align-items: center;
  justify-content: center;
}
.actions .changenumber {
  border: none;
  background: rgb(0, 0, 0);
  color: white;
  border-radius: 5px;
  /* height: 20px; */
  cursor: pointer;
  padding: 5px;
  height: 30px;
  font-size: 15px;
}
.actions .pausenumber {
  border: none;
  background: rgb(90, 23, 220);
  color: white;
  border-radius: 5px;
  /* height: 20px; */
  cursor: pointer;
  padding: 5px;
  height: 30px;
  font-size: 15px;
}

.Flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Row {
  display: flex;
  /* justify-content: space-between; */
}

.Row p {
  margin-right: 20px;
}

.changeonemodal h2 {
  font-size: 20px;
  font-weight: 800;
}

/* .changeonemodal */

.actions1 {
  display: flex;
  align-items: start;
  justify-content: start;
  width: 100%;
  flex-wrap: wrap;
  /* flex-flow: column; */
}

.actions1 p {
  margin-right: 10px;
}

.changes {
  text-align: left;
  display: flex;
}
.changes p {
  margin-right: 10px;
}

.prevchanges {
  font-weight: 800;
  margin: 0px;
  padding: 0px;
}

.changeColumn {
  margin-right: 10px;
  background: rgb(90, 23, 220, 0.05);
  padding: 10px;
  border-radius: 10px;
}

.Scrollable {
  height: 680px;
  width: 100%;
  overflow-y: scroll;
}

.Selects {
  display: flex;
  flex-wrap: wrap;
}

.Selects select {
  flex: 1;
  padding: 10px 0px;
  border: none;
  /* box-shadow: 1px 0px 10px #eee; */
  border-bottom: 1px solid #bbb;
  /* margin-r: 10px; */
}
.Selects select:nth-child(1) {
  margin-right: 10px;
}
.Selects select:nth-child(2) {
  margin-left: 10px;
}

.createnewset {
  display: flex;
  /* background-color: green; */
  width: 100%;
}
.createnewset button {
  border: none;
  padding: 5px 10px;
  margin-right: 10px;
  background: black;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.fileupload {
}
